import './App.css';
import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Main from './components/Main';
import GlobalStyles from './styles/GlobalStyles';
import axios from 'axios';
import { Navigate  } from 'react-router-dom';
import { clearToken as clearFcmToken } from './firebase';

axios.defaults.baseURL = 'https://api.hamahangim.com';

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.token = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.clear('token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

function App() {
  const [isLogedIn, setIsLogedIn] = useState(localStorage.getItem('token') ? true : false);

  useEffect(() => {
    if (!isLogedIn) {
      try {
        clearFcmToken();
      } catch (error) {
        
      }
    }
  }, [isLogedIn])

  return (
    <>
      <GlobalStyles />
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={ <Login setIsLogedIn={ setIsLogedIn } isLogedIn={ isLogedIn } /> } />
          <Route path="/" element={ isLogedIn ? <Main /> : <Navigate  to="/login" /> } />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
