import React, { useState, useEffect } from "react";
import axios from "axios";
import { styled } from "styled-components";
import Collection from './Collection.js';

const Main = styled.div`
    width: 50%;
    height: 100%;
    background-color: white;
    padding: 10px 0 70px 0;
    display: flex;
    float: right;
    flex-wrap: wrap;
    overflow: auto;
`;

const SongDiv = styled.div`
    width: 96%;
    margin: 0.5rem auto;
    box-shadow: 0 1px 1px -1px gray;
    height: 58px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
`;

const ContentDiv = styled.div`
    width: 100%;
    display: flex;
    flex-grow: 1;
`;

const ImageDiv = styled.div`
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 7px;
    background-color: white;
    margin: 6px 9px;
    overflow: hidden;
`;

const TextDiv = styled.div`
    height: calc(100% - 8px);
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    line-height: 1.4rem;
    color: #373737;
`;

const TitleDive = styled.div`
    flex-grow: 1;
    font-size: 0.8rem;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1; 
    -webkit-box-orient: vertical;
`;

const SingerDiv = styled.div`
    flex-grow: 1;
    font-size: 0.5rem;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1; 
    -webkit-box-orient: vertical;
`;

const SongsContainer = ({ songs, changeSong }) => {
    return (
        <Main>
            {songs.map((song) => (
                <SongDiv key={song.id} onClick={() => changeSong(song.id)}>
                    <ContentDiv>
                        <ImageDiv>
                            <img width="100%" src={`https://media1.hamahangim.com/img/song/${song.id}.jpg`} />
                        </ImageDiv>
                        <TextDiv>
                            <TitleDive>{song.songName}</TitleDive>
                            <SingerDiv>{song.singerName}</SingerDiv>
                        </TextDiv>
                    </ContentDiv>
                </SongDiv>
            ))}
        </Main>
    );
}

export default SongsContainer;