import React, { useState, useEffect } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import { requestPermission, onMessageListener } from '../firebase';

function Notification({ setSong }) {
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [payload, setPayload] = useState(null);
  useEffect(() => {
    requestPermission();
    const unsubscribe = onMessageListener().then((payload) => {
      if (payload?.notification) {
        setNotification({
          title: payload?.notification?.title,
          body: payload?.notification?.body,
        });
        toast.success(`${payload?.notification?.title}: ${payload?.notification?.body}`, {
          duration: 10000,
          position: 'top-center',
        });
      } else if (payload?.data) {
        setPayload(payload);
        if (payload?.data?.a === 'cs') {
          setSong(JSON.parse(payload?.data?.song));
        }
      }
    });
    return () => {
      unsubscribe.catch((err) => console.log('failed: ', err));
    };
  }, [notification, payload]);
  return (
    <div>
      <Toaster />
    </div>
  );
}
export default Notification;