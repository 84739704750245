import styled from 'styled-components';

const PlayerDiv = styled.div`
    position: fixed;
    bottom: 0.3rem;
    left: 2%;
    width: 96%;
    height: 58px;
    background-color: #f58730;
    border-radius: 50rem;
    display: flex;
    overflow: hidden;
    flex-direction: column;
`;

const ContentDiv = styled.div`
    width: 100%;
    display: flex;
    flex-grow: 1;
`;

const ImageDiv = styled.div`
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: white;
    margin: 6px 9px;
    overflow: hidden;
`;

const TextDiv = styled.div`
    height: calc(100% - 8px);
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    line-height: 1.4rem;
    color: white;
`;

const TitleDive = styled.div`
    flex-grow: 1;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1; 
    -webkit-box-orient: vertical;
`;

const SingerDiv = styled.div`
    flex-grow: 1;
    font-size: 0.6rem;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1; 
    -webkit-box-orient: vertical;
`;

const ButtonDiv = styled.div`
    width: 100px;
    margin: 5px;
    overflow: hidden;
    display: flex;
    align-items: center;
`;

const TimeLineDiv = styled.div`
    width: calc(100% - 50px);
    height: 3px;
    margin: auto;
    border-radius: 10px;
    background-color: #d4f4fc;
`;

const TimeLineBar = styled.div`
    height: 100%;
    background-color: #f58730;
    border-radius: 10px;
    float: left;
    
    -webkit-transition: all 180ms linear;
    -moz-transition: all 180ms linear;
    -o-transition: all 180ms linear;
    transition: all 180ms linear;
`;
const Player = ( { isPlaying, playAudio, pauseAudio, myTimeLine, friendTimeLine, songName, singerName, songId } ) => {
    return (
        <PlayerDiv>
            <TimeLineDiv>
                <TimeLineBar style={{width: `${myTimeLine}%`}} />
            </TimeLineDiv>
            <ContentDiv>
                <ImageDiv>
                    <img width="100%" src={`https://media1.hamahangim.com/img/song/${songId}.jpg`} />
                </ImageDiv>
                <TextDiv>
                    <TitleDive>{songName}</TitleDive>
                    <SingerDiv>{singerName}</SingerDiv>
                </TextDiv>
                <ButtonDiv>

                <svg
                    width="30"
                    height="16"
                    fill="white"
                    viewBox="0 0 16 16"
                    transform="scale(1.3, 1.3)">
                    <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                    <path d="m16.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                </svg>

                <svg
                    width="35"
                    height="16"
                    fill="white"
                    viewBox="0 0 16 16"
                    transform="scale(1.3, 1.3)"
                    onClick={() => (isPlaying ? pauseAudio() : playAudio())}
                >
                    <path d={isPlaying ? '4' : "m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"}/>
                </svg>

                <svg
                    width="30"
                    height="16"
                    fill="white"
                    viewBox="0 0 16 16"
                    transform="scale(-1.3, 1.3)">
                    <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                    <path d="m16.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                </svg>

                </ButtonDiv>
            </ContentDiv>
            <TimeLineDiv>
                <TimeLineBar style={{width: `${friendTimeLine}%`}} />
            </TimeLineDiv>
        </PlayerDiv>
    );
}

export default Player;